export const deOptions =  [
    {
        "value": "内科",
        "label": "内科",
        "children": [
            {
                "value": "呼吸内科",
                "label": "呼吸内科",
                
            },
            {
                "value": "消化内科",
                "label": "消化内科",
                
            },
            {
                "value": "神经内科",
                "label": "神经内科",
                
            },
            {
                "value": "心血管内科",
                "label": "心血管内科",
                
            },
            {
                "value": "肾内科",
                "label": "肾内科",
                
            },
            {
                "value": "血液内科",
                "label": "血液内科",
                
            },
            {
                "value": "免疫科",
                "label": "免疫科",
                
            },
            {
                "value": "内分泌科",
                "label": "内分泌科",
                
            }
        ]
    },
    {
        "value": "外科",
        "label": "外科",
        "children": [
            {
                "value": "普通外科",
                "label": "普通外科",
                
            },
            {
                "value": "神经外科",
                "label": "神经外科",
                
            },
            {
                "value": "心胸外科",
                "label": "心胸外科",
                
            },
            {
                "value": "泌尿外科",
                "label": "泌尿外科",
                
            },
            {
                "value": "心血管外科",
                "label": "心血管外科",
                
            },
            {
                "value": "乳腺外科",
                "label": "乳腺外科",
                
            },
            {
                "value": "肝胆外科",
                "label": "肝胆外科",
                
            },
            {
                "value": "器官移植",
                "label": "器官移植",
                
            },
            {
                "value": "肛肠外科",
                "label": "肛肠外科",
                
            },
            {
                "value": "烧伤科",
                "label": "烧伤科",
                
            },
            {
                "value": "骨外科",
                "label": "骨外科",
                
            }
        ]
    },
    {
        "value": "妇产科",
        "label": "妇产科",
        "children": [
            {
                "value": "妇科",
                "label": "妇科",
                
            },
            {
                "value": "产科",
                "label": "产科",
                
            },
            {
                "value": "计划生育",
                "label": "计划生育",
                
            },
            {
                "value": "妇幼保健",
                "label": "妇幼保健",
                
            }
        ]
    },
    {
        "value": "男科",
        "label": "男科",
        
    },
    {
        "value": "儿科",
        "label": "儿科",
        "children": [
            {
                "value": "儿科综合",
                "label": "儿科综合",
                
            },
            {
                "value": "小儿内科",
                "label": "小儿内科",
                
            },
            {
                "value": "小儿外科",
                "label": "小儿外科",
                
            },
            {
                "value": "新生儿科",
                "label": "新生儿科",
                
            },
            {
                "value": "儿童营养保健科",
                "label": "儿童营养保健科",
                
            }
        ]
    },
    {
        "value": "五官科",
        "label": "五官科",
        "children": [
            {
                "value": "耳鼻喉科",
                "label": "耳鼻喉科",
                
            },
            {
                "value": "眼科",
                "label": "眼科",
                
            },
            {
                "value": "口腔科",
                "label": "口腔科",
                
            }
        ]
    },
    {
        "value": "肿瘤科",
        "label": "肿瘤科",
        "children": [
            {
                "value": "肿瘤内科",
                "label": "肿瘤内科",
                
            },
            {
                "value": "肿瘤外科",
                "label": "肿瘤外科",
                
            },
            {
                "value": "肿瘤妇科",
                "label": "肿瘤妇科",
                
            },
            {
                "value": "骨肿瘤科",
                "label": "骨肿瘤科",
                
            },
            {
                "value": "放疗科",
                "label": "放疗科",
                
            },
            {
                "value": "肿瘤康复科",
                "label": "肿瘤康复科",
                
            },
            {
                "value": "肿瘤综合科",
                "label": "肿瘤综合科",
                
            }
        ]
    },
    {
        "value": "皮肤性病科",
        "label": "皮肤性病科",
        "children": [
            {
                "value": "皮肤科",
                "label": "皮肤科",
                
            },
            {
                "value": "性病科",
                "label": "性病科",
                
            }
        ]
    },
    {
        "value": "中医科",
        "label": "中医科",
        "children": [
            {
                "value": "中医全科",
                "label": "中医全科",
                
            },
            {
                "value": "中医内科",
                "label": "中医内科",
                
            },
            {
                "value": "中医外科",
                "label": "中医外科",
                
            },
            {
                "value": "中医妇科",
                "label": "中医妇科",
                
            },
            {
                "value": "中医儿科",
                "label": "中医儿科",
                
            },
            {
                "value": "中医保健科",
                "label": "中医保健科",
                
            },
            {
                "value": "针灸按摩科",
                "label": "针灸按摩科",
                
            },
            {
                "value": "中医骨伤科",
                "label": "中医骨伤科",
                
            },
            {
                "value": "中医肿瘤科",
                "label": "中医肿瘤科",
                
            }
        ]
    },
    {
        "value": "传染科",
        "label": "传染科",
        "children": [
            {
                "value": "肝病科",
                "label": "肝病科",
                
            },
            {
                "value": "艾滋病科",
                "label": "艾滋病科",
                
            },
            {
                "value": "结核病",
                "label": "结核病",
                
            },
            {
                "value": "寄生虫",
                "label": "寄生虫",
                
            }
        ]
    },
    {
        "value": "精神心理科",
        "label": "精神心理科",
        "children": [
            {
                "value": "精神科",
                "label": "精神科",
                
            },
            {
                "value": "心理咨询科",
                "label": "心理咨询科",
                
            }
        ]
    },
    {
        "value": "整形美容科",
        "label": "整形美容科",
        
    },
    {
        "value": "营养科",
        "label": "营养科",
        
    },
    {
        "value": "生殖中心",
        "label": "生殖中心",
        
    },
    {
        "value": "麻醉医学科",
        "label": "麻醉医学科",
        "children": [
            {
                "value": "麻醉科",
                "label": "麻醉科",
                
            },
            {
                "value": "疼痛科",
                "label": "疼痛科",
                
            }
        ]
    },
    {
        "value": "医学影像科",
        "label": "医学影像科",
        "children": [
            {
                "value": "核医学科",
                "label": "核医学科",
                
            },
            {
                "value": "放射科",
                "label": "放射科",
                
            },
            {
                "value": "超声科",
                "label": "超声科",
                
            }
        ]
    },
    {
        "value": "其它科室",
        "label": "其它科室",
        "children": [
            {
                "value": "药剂科",
                "label": "药剂科",
                
            },
            {
                "value": "护理科",
                "label": "护理科",
                
            },
            {
                "value": "体检科",
                "label": "体检科",
                
            },
            {
                "value": "检验科",
                "label": "检验科",
                
            },
            {
                "value": "急诊科",
                "label": "急诊科",
                
            },
            {
                "value": "公共卫生与预防科",
                "label": "公共卫生与预防科",
                
            },
            {
                "value": "全科",
                "label": "全科",
                
            },
            {
                "value": "设备科",
                "label": "设备科",
                
            }
        ]
    }
]