<template>
  <div id="app">
    <div class="header-wrap fdr aic">
        <div>智能雷达系统 | 后台管理</div>
    </div> 
    <div>
        <div class="doctor-wrap">
            <el-card class="box-card">
                <div class="title-wrap fdr aic jcsb">
                    <div>版本管理</div>
                    <el-button type="primary"  size="small" @click="dialogVisible = true; dialogType = 3; dialogTitle = '添加版本'">添加版本</el-button>
                </div>
                <div class="table-wrap">
                    <el-table
                    :data="versionList"
                    stripe
                    border
                    style="width: 100%">
                    <el-table-column
                      prop="versionName"
                      label="版本号"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="description"
                      label="版本描述"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="createTime"
                      label="上传时间"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="fileUrl"
                      label="版本文件"
                    >
                      <template slot-scope="{row}">
                        <a :href="'//'+row.fileUrl" target="_blank">{{row.fileUrl.split('/').pop().replace('/', '')}}</a>
                      </template>
                    </el-table-column>
                    <el-table-column
                    fixed="right"
                    label="操作"
                    width="150"
                    >
                    <template slot-scope="scope">
                    <!-- <el-button style="margin-right: 10px;" type="text" size="small" slot="reference"  @click="editItem(scope.row, 1)">编辑</el-button> -->
                        <el-popconfirm
                            title="确定删除吗？"
                            @onConfirm="deleteVersion(scope.row.id)"
                            >
                            <el-button type="text" size="small" slot="reference" >删除</el-button>
                        </el-popconfirm>
                    </template>
                  </el-table-column>
                  </el-table>
                </div>
              </el-card>
        </div>
        <div class="doctor-wrap">
            <el-card class="box-card">
                <div class="title-wrap fdr aic jcsb">
                    <div>设备管理</div>
                    <el-button type="primary"  size="small" @click="dialogVisible = true; dialogType = 1; dialogTitle = '添加设备'">添加设备</el-button>
                </div>
                <div class="table-wrap">
                    <el-table
                    :data="deviceList"
                    stripe
                    border
                    style="width: 100%">
                    <el-table-column
                      prop="name"
                      label="设备名称"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="version"
                      label="设备版本"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="port"
                      label="设备端口"
                    >
                    </el-table-column>
                    <!-- <el-table-column
                    prop="mac"
                    label="设备MAC地址"
                    min-width="150"
                    ></el-table-column> -->
                    <el-table-column
                    prop="mac"
                    label="设备序列号"
                    min-width="150"
                    ></el-table-column>
                    <el-table-column
                      prop="status"
                      label="设备状态"
                    >
                      <template slot-scope="{row}">
                          <span v-if="row.status" class="dot" :class="{ 'outline' : row.status == 2 }"></span>
                          <span>{{ { 1:'在 线', 2:'离 线' }[row.status] || '--' }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                    prop="radarHeight"
                    label="设备高度"
                    ></el-table-column>
                    <el-table-column
                    prop="downAngle"
                    label="设备角度"
                    ></el-table-column>
                    <!-- <el-table-column
                    prop="bandWidth"
                    label="设备带宽"
                    ></el-table-column> -->
                    <el-table-column
                      prop="isUpdate"
                      label="更新状态"
                    >
                    </el-table-column>
                    <el-table-column
                    fixed="right"
                    label="操作"
                    width="150"
                    >
                    <template slot-scope="scope">
                    <el-button style="margin-right: 10px;" type="text" size="small" slot="reference"  @click="editItem(scope.row, 1)">编辑</el-button>
                        <el-popconfirm
                            title="确定删除吗？"
                            @onConfirm="deleteDevice(scope.row.id)"
                            >
                            <el-button type="text" size="small" slot="reference" >删除</el-button>
                        </el-popconfirm>
                    </template>
                  </el-table-column>
                  </el-table>
                </div>
              </el-card>
        </div>
        <div class="patient-wrap">
            <el-card class="box-card">
                <div class="title-wrap fdr aic jcsb">
                    <div>用户管理</div>
                    <div>
                      <el-button type="primary"  size="small" @click="dialogVisible = true; dialogType = 2; dialogTitle = '添加用户'">添加用户</el-button>
                    </div>
                </div>
                <div class="table-wrap">
                    <el-table
                    :data="userList"
                    stripe
                    border
                    style="width: 100%">
                    <el-table-column
                      prop="name"
                      label="姓名"
                      min-width="150"
                      >
                    </el-table-column>
                    <el-table-column
                      prop="parentId"
                      label="是否主账号"
                      align='center'
                      min-width="120"
                    >
                      <template slot-scope="scope">
                          <el-tag v-if="!scope.row.parentId" type="primary" size="mini">主账号</el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column
                    prop="phone"
                    label="登陆号码"
                    min-width="150"
                    >
                  </el-table-column>
                  <el-table-column
                    prop="password"
                    label="登录密码"
                    min-width="120"
                  >
                  </el-table-column>
                  <el-table-column
                    fixed="right"
                    label="操作"
                    align="center"
                    min-width="150">
                    <template slot-scope="scope">
                        <div class="fdr aic jcc">
                          <el-button style="margin-right: 10px;" type="text" size="small" slot="reference"  @click="editItem(scope.row, 2)">编辑</el-button>
                          <el-button style="margin-right: 10px;" type="text" size="small" slot="reference"  @click="openBand(scope.row)">关联设备</el-button>
                        </div>
                    </template>
                  </el-table-column>
                  </el-table>
                </div>
          </el-card>
        </div>
        <!-- <div class="doctor-wrap">
            <el-card class="box-card">
                <div class="title-wrap fdr aic jcsb">
                    <div>告警信息</div>
                </div>
                <div class="table-wrap">
                    <el-table
                    :data="warningList"
                    stripe
                    border
                    style="width: 100%">
                    <el-table-column
                      prop="name"
                      label="设备名称"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="port"
                      label="端口"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="port"
                      label="情况描述"
                    >
                    </el-table-column>
                    <el-table-column
                    fixed="right"
                    label="操作"
                    width="150"
                    >
                      <template slot-scope="scope">
                        <el-button style="margin-right: 10px;" type="text" size="small" slot="reference"  @click="editItem(scope.row, 1)">编辑</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
            </el-card>
        </div> -->
    </div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="50%"
        :close-on-click-modal="false"
        :before-close="handleClose">
        <div>
            <el-form label-position="right" label-width="100px" :model="form" v-if="dialogType == 1">
                <el-form-item label="设备名称" size="small">
                  <el-input v-model="form.name"></el-input>
                </el-form-item>
                <!-- <el-form-item label="设备端口" size="small">
                  <el-input v-model="form.port"></el-input>
                </el-form-item> -->
                <!-- <el-form-item label="设备MAC地址" size="small">
                  <el-input v-model="form.mac"></el-input>
                </el-form-item> -->
                <el-form-item label="设备序列号" size="small">
                  <el-input v-model="form.mac"></el-input>
                </el-form-item>
                <el-form-item label="设备高度" size="small">
                  <el-input v-model="form.radarHeight"></el-input>
                </el-form-item>
                <el-form-item label="设备下方角度" size="small">
                  <el-input v-model="form.downAngle"></el-input>
                </el-form-item>
                
                <!-- <el-form-item label="设备带宽" size="small">
                  <el-input v-model="form.bandWidth"></el-input>
                </el-form-item> -->
              </el-form>
              <el-form label-position="left" label-width="80px" :model="form" v-else-if="dialogType == 2">
                <el-form-item label="姓名" size="small">
                  <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="登录号码" size="small">
                  <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="登录密码" size="small">
                  <el-input v-model="form.password"></el-input>
                </el-form-item>
                <!-- <el-form-item label="关联设备" size="small">
                    <el-select v-model="form.association_device" placeholder="请选择"  multiple collapse-tags>
                        <el-option
                          v-for="item in deviceList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                        </el-option>
                      </el-select>
                </el-form-item> -->
                <el-form-item label="分享账号" size="small">
                  <div v-for="(item,index) in accountList" :key="index" style='display:flex;margin-bottom:8px'>
                      <el-input v-model="item.value" style="width:60%"></el-input>
                      <div style="width:100px;margin-left:20px">
                         <el-button type="danger" size='mini' icon="el-icon-minus" @click='delAccount(index)'></el-button>
                         <el-button type="primary" size='mini' icon="el-icon-plus" @click="addAccount" v-if="index == accountList.length -1"></el-button>
                      </div>
                  </div>
                </el-form-item>
              </el-form>
              <el-form label-position="left" label-width="80px" :model="form" v-else-if="dialogType == 3">
                <el-form-item label="版本号" size="small">
                  <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="版本描述" size="small">
                  <el-input v-model="form.description" type="textarea"
                  :rows="3"></el-input>
                </el-form-item>
                <el-form-item label="版本文件" size="small">
                  <el-upload
                    class="upload-demo"
                    :limit="1"
                    action=""
                    ref="upload"
                    :on-change="changFile"
                    :auto-upload="false"
                    >
                    <el-button size="small" type="primary">选择文件</el-button>
                  </el-upload>
                </el-form-item>
              </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false;initData()" size="small">取 消</el-button>
            <el-button type="primary" @click="modifyUser(dialogType)" size="small" v-loading="loading">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog
        title="绑定设备"
        :visible.sync="bandDialog"
        width="650px"
        :close-on-click-modal="false"
        :before-close="closeBand">
        <div>
                  <!-- <el-select v-model="deviceIds" multiple placeholder="请选择" style="width:80%">
                      <el-option v-for="item in deviceList" :key='item.index' :value="item.id" :label='item.name'></el-option>
                  </el-select> -->
            <el-transfer v-model="deviceIds" :data="bandDeviceList" :titles="['所有设备', '已绑定设备']"></el-transfer>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeBand" size="small">取 消</el-button>
            <el-button type="primary" @click="bandUser" size="small">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import {getDevice,  getUser, addDevice, editDevice, delDevice,addUser, editUser, delUser,getVersion, addVersion, delVersion, bandUserDevice, queryUserBandList, queryUserDevice } from './js/api.js'
import { deOptions } from './js/data.js'
export default{
    data() {
      return {
          accountList:[{
            value:'',
          }],
          bandDialog:false,
          userId:'',
          deviceIds:[],
          deviceTimer:null,
          deviceList: [],
          bandDeviceList:[],
          versionList: [],
          fileObj: null,
          deviceObj: {},
          form: {},
          userList: [],
          warningList:[],
          dialogTitle: '',
          seen: true,
          dialogVisible: false,
          deOptions:deOptions,
          // 1 医生 2 患者
          dialogType: 1,
          loading: false,
          patientList: [],
          options: [{
              label: '男',
              value: '男'
          },
          {
              label: '女',
              value: '女'
          }]
      };
    },
    beforeDestroy(){
        if(this.deviceTimer) clearInterval(this.deviceTimer);
    },
    mounted() {
      this.queryVersion()
        this.queryDevice()
        this.deviceTimer = setInterval(() => {
          this.queryDevice()
        },1115000)    // 增加5秒轮询，查询设备最新状态
    },
    methods: {
      changFile(file, fileList){
        this.fileObj = file.raw
      },
      queryDevice(){
        getDevice().then((res) => {
            this.deviceList = res.data
            this.bandDeviceList = res.data.map(item => {
              return {
                id:item.id,
                label:item.name,
                key:item.id,
              }
            })
            res.data.forEach((item) => {
              this.deviceObj[item.id] = item.name
            })
            this.queryUser()
        }).catch((e) => {
            console.log('===err', e);
        }) 
      },
      queryVersion(){
        getVersion().then((res) => {
            this.versionList = res.data
        }).catch((e) => {
            console.log('===err', e);
        }) 
      },
      addAccount(){
        if(this.accountList[this.accountList.length - 1].value == ''){
          this.$message.warning('请将本次填写完整再添加')
        }else{
          this.accountList.push({
            value:''
          })
        }
      },
      delAccount(i){
        if(this.accountList.length > 1){
          this.accountList.splice(i,1)
        }else{
            this.$message.warning('已经是最后一个啦')
        }
      },
      openBand(row){
          this.bandDialog = true
          this.userId = row.id
          this.queryUserDeviceList(row.id)
          queryUserDevice({
            userId:row.id
          }).then(res=>{})
      },
      bandUser(){
         bandUserDevice({
            userId:this.userId,
            deviceIds:this.deviceIds,
         }).then(res => {
            if(res.code == 200){
              this.$message.success(res.msg)
              this.closeBand()
            }
         })
      },  
      queryUserDeviceList(id){
        queryUserBandList({
          userId:id
        }).then(res => {
           this.deviceIds = res.data.map(item => item.deviceId)
        })
      },  
      closeBand(){
        this.bandDialog = false
        this.userId = ''
        this.deviceIds = []
      },
      createShareUser(){
        
      },
      queryUser: function(){
        getUser().then((res) => {
            this.userList = res.data
        }).catch((e) => {
            console.log('===err', e);
        }) 
      },
      handleUser:function(){
        let accounts = [] 
        if(this.accountList && this.accountList.length > 0) accounts = this.accountList.map(item => item.value);
        console.log(accounts)
        addUser({
            "name": this.form.name,
            "phone": this.form.phone,
            "password": this.form.password,
            // "association_device": this.form.association_device.join(','),
            "shareAccount": accounts.join(','),
            "parentId":"",
        }).then((res) => {
            this.queryUser()
            this.form = {}
        })
      },
      upUser:function(){
        let accounts = [] 
        if(this.accountList && this.accountList.length > 0) accounts = this.accountList.map(item => item.value);
        editUser({
            "name": this.form.name,
            "phone": this.form.phone,
            "password": this.form.password,
            "shareAccount": accounts.join(','),
            // "association_device": this.form.association_device.join(','),
            id: this.form.id
        }).then((res) => {
            this.queryUser()
            this.form = {}
        })
      },
      deleteUser: function(id){
        delUser({
          id
        }).then((res) => {
            this.queryUser()
            this.form = {}
        })
      },
      handleDevice:function(){
        addDevice(this.form).then((res) => {
            this.queryDevice()
            this.form = {}
        })
      },
      upDevice:function(){
        editDevice(this.form).then((res) => {
            this.queryDevice()
            this.form = {}
        })
      },
      deleteVersion: function(id){
        delVersion({
          id
        }).then((res) => {
            this.queryVersion()
            this.form = {}
        })
      },
      deleteDevice: function(id){
        delDevice({
          id
        }).then((res) => {
            this.queryDevice()
            this.form = {}
        })
      },
      modifyUser: function(type){
          if(type == 1 && this.dialogTitle.includes('添加')){
              this.handleDevice()
              this.dialogVisible = false;
          }else if(type == 1 && this.dialogTitle.includes('编辑')){
              this.upDevice()
              this.dialogVisible = false;
          }else if(type == 2 && this.dialogTitle.includes('添加')){
              this.handleUser()
              this.dialogVisible = false;
          }else if(type == 2 && this.dialogTitle.includes('编辑')){
              this.upUser()
              this.dialogVisible = false;
          }else if(type == 3 && this.dialogTitle.includes('添加')){
            const formData = new FormData();
            this.loading = true
            formData.append('file', this.fileObj);
            formData.append('name', this.form.name);
            formData.append('description', this.form.description);
            addVersion(formData).then((res) => {
              console.log("res", res);
              this.dialogVisible = false;
                this.$refs.upload.clearFiles();
                this.queryVersion()
                this.form = {}
                this.loading = false
            })
          }
      },
      editItem: function(item, type){
        console.log('======item', item);
        if(type == 1){
          this.form = item
          // this.$set(this.form, 'name', item.name)
          // this.$set(this.form, 'port', item.port)
          // this.$set(this.form, 'id', item.id)
        }else{
          this.accountList = item.shareAccount ? item.shareAccount.split(',').map((item)=>{
            return {
              value:item
            }
          }) : [{
            value:''
          }]
          this.$set(this.form, 'name', item.name)
          this.$set(this.form, 'phone', item.phone)
          this.$set(this.form, 'password', item.password)
          // this.$set(this.form, 'association_device', item.association_device.split(',').map((item)=> Number(item)))
          this.$set(this.form, 'id', item.id)
        }
        this.dialogVisible = true; 
        this.dialogType = type; 
        this.dialogTitle = `编辑${type == 1 ? '设备' : '用户' }`
      },
        handleChange: function(val){
            console.log('======val', val);
        },
        initData: function(){
          this.$refs.upload && this.$refs.upload.clearFiles();
            this.form = {}
            this.accountList = [{
              value:''
            }]
        },
         handleClose: function (done) {
            this.initData()
            done()
        },
    
    },
}
</script>

<style>
   html, body{
        margin: 0;
        padding: 0;
    }
    #app{
        width: 100%;
        height: 100%;
    }
    .dot{
       display: inline-block;
       width:9px;
       height: 9px;
       border-radius: 50%;
       background: #409EFF;
       box-shadow: 0px 0px 4px 1px #63abf5;
       margin-right:6px;
       position:relative;
       top:-1px;
    }
    .outline{
      background: #909399;
      box-shadow: 0px 0px 4px 1px #b2b3b6;
    }
    .header-wrap{
        height: 40px;
        background: linear-gradient(to right, #285dff, #5378ff);
        padding: 0 20px;
        color: #fff;
        font-weight: bold;
    }
    .fdr{
        display: flex;
        flex-direction: row;
    }
    .fdc{
        display: flex;
        flex-direction: column;
    }
    .aic{
        align-items: center;
    }
    .jcc{
        justify-content: center;
    }
    .jcsb{
        justify-content: space-between;
    }
    .doctor-wrap{
        width: 100%;
        box-sizing: border-box;
        padding: 10px 20px;
    }
    .patient-wrap{
        width: 100%;
        box-sizing: border-box;
        padding: 10px 20px;
    }
    .table-wrap{
        width: 100%;
        overflow: auto;
    }
    .title-wrap{
        margin-bottom: 20px;
        font-weight: bold;
    }
</style>./js/data.js
